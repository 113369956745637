<template>
  <div>
    <a-tabs tab-position="left" v-model:activeKey="tab">
      <a-tab-pane key="settings" tab="Настройки профиля">
        <div class="d-flex flex-column" style="gap: 12px">
          <a-typography-title :level="4">Редактирование профиля</a-typography-title>
          <a-form @submit="submit" layout="vertical" :model="profile" style="max-width: 400px">
            <a-form-item label="Имя пользователя">
              <a-input v-model:value="profile.username" placeholder="Введите имя пользователя" />
            </a-form-item>
            <a-form-item label="Email адрес">
              <a-input disabled v-model:value="profile.email" placeholder="Введите адрес" />
            </a-form-item>
            <a-form-item label="Роль">
              <a-input disabled :value="roleName" />
            </a-form-item>
            <a-form-item>
              <a-button :loading="submitLoading" @click="submit" type="primary">Обновить данные</a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-tab-pane>
      <a-tab-pane key="password" tab="Обновить пароль">
        <div class="d-flex flex-column" style="gap: 12px">
          <a-typography-title :level="4">Обновить пароль</a-typography-title>
          <a-form @submit="update" layout="vertical" :model="profile" style="max-width: 400px">
            <a-form-item has-feedback label="Старый пароль" name="old">
              <a-input v-model:value="password.old" type="password" placeholder="Введите старый пароль" autocomplete="off" />
            </a-form-item>
            <a-form-item has-feedback label="Новый пароль" name="new">
              <a-input v-model:value="password.new" type="password" placeholder="Введите новый пароль" autocomplete="off" />
            </a-form-item>
            <a-form-item has-feedback label="Подтверждение нового пароля" name="confirm">
              <a-input v-model:value="password.confirm" type="password" placeholder="Подтвердите новый пароль" autocomplete="off" />
            </a-form-item>
            <a-form-item>
              <a-button :loading="updateLoading" @click="update" type="primary">Обновить пароль</a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { notification } from 'ant-design-vue'
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import apiClient from '@/services/axios'

const store = useStore()
const tab = ref('settings')
const updateLoading = ref(false)
const submitLoading = ref(false)
const user = computed(() => store.getters['user/user'])
const roleName = computed(() => {
  switch (user.value.role) {
    case 'admin':
      return 'Администратор'
    case 'brandmanager':
      return 'Брендменеджер'
    case 'manager':
      return 'Менеджер'
    case 'engineer':
      return 'Инженер'
    case 'cashier':
      return 'Кассир'
    case 'support':
      return 'Support'
    default:
      return user.value.role
  }
})
const profile = reactive({
  username: '',
  email: '',
  role: ''
})
const password = reactive({
  old: '',
  new: '',
  confirm: ''
})

onMounted(() => {
  nextTick(() => {
    profile.username = user.value.username
    profile.email = user.value.email
    profile.role = user.value.role
  })
})

const submit = () => {
  submitLoading.value = true
  apiClient.post('/admin/profile', {
    username: profile.username
  }).then(({ data: response }) => {
    if (response.success) {
      notification.success({ message: 'Данные успешно обновлены' })
    }
  }).catch(e => console.log(e)).finally(() => {
    submitLoading.value = false
  })
}
const update = () => {
  if (password.new.length < 6) {
    notification.error({ message: 'Пароль должен состоять хотя бы из 6 символов' })
    return
  }
  if (password.new !== password.confirm) {
    notification.error({ message: 'Пароли не совпадают' })
    return
  }
  updateLoading.value = true
  apiClient.post('/admin/password', {
    old_password: password.old,
    new_password: password.new
  }).then(({ data: response }) => {
    if (response.success) {
      notification.success({ message: 'Пароль успешно обновлен' })
      password.old = ''
      password.new = ''
      password.confirm = ''
    }
  }).catch(e => console.log(e)).finally(() => {
    updateLoading.value = false
  })
}
</script>

<style scoped>
:deep(.ant-tabs .ant-tabs-left-bar .ant-tabs-tab) {
  padding: 10px;
  min-width: 200px;
  text-align: left;
}
</style>
